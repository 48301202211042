<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i>
                    <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA INGRESO DE DOCUMENTOS STAGE 1 </span>
                    <hr />
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaReports.length }}</span>
                            <br />
                            <span class="text-muted h6">Reports</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de ingreso de documentos stage 1 </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión report stage 1' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo report
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i>
                    <span class="h5"> Ingreso de documentos stage 1 registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <b-tabs variant="pills">
                                    <b-tab active>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Reports
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaReports" :fields="campoReports" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <!-- <template #table-colgroup="campoReports">
                                                        <col v-for="field in campoReports.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template> -->
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión report stage 1',params: { id: param.item.idReport },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarReport(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                </b-tabs>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            datosSession: {
                idCliente: '',
            },

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [
                10,
                25,
                50,
                100,
                {
                    value: 9999999,
                    text: 'Mostrar todos',
                },
            ],
            campoReports: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                // {
                //     key: 'cliente',
                //     label: 'Empresa',
                //     class: 'text-center',
                // },
                {
                    key: 'normas',
                    label: 'Normas',
                    class: 'text-center',
                },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015',
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015',
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018',
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016',
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013',
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011',
                },
            ],
            listaReports: [],
        };
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        listarReports() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-reports-s1', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaReports = response.data;
                    me.totalRows = me.listaReports.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },

        eliminarReport(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el report?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-pack-report-s1', {
                                    idReport: param.item.idReport,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarReports();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },

        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarReports();
        }
    },
};
</script>
